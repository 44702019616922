<template>
  <b-col md="6" :xl="roleItem.col ? roleItem.col : 4">
    <router-link
      :to="{ name: 'web-settings-roles-permissions', params: { id: roleItem.id } }"
    >
      <b-card
        :img-src="roleItem.image"
        overlay
        text-variant="white"
        img-alt="card img"
        body-class="bg-overlay"
        class="card-role shadow-lg"
      >
        <b-card-title class="text-white">
          {{ roleItem.name }}
        </b-card-title>
        <div class="d-flex flex-column justify-content-around h-75">
          <b-card-text>
            This is a wider card with supporting text below as a natural lead-in
            to additional content. This content is a little bit longer.
          </b-card-text>
          <b-card-text>
            <small class="text-muted">Last updated 3 mins ago</small>
          </b-card-text>
          <b-card-text class="text-right">
            <b-badge variant="primary" class="text-muted">{{
              roleItem.name
            }}</b-badge>
          </b-card-text>
        </div>
      </b-card>
    </router-link>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BBadge,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BBadge,
  },
  props: {
    roleItem: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style scoped>
.card-role:hover {
  transform: translateY(-5px);
}
</style>