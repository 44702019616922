<template>
  <div>
    <b-row>
      <CardRole
        v-for="(roleItem, index) in roles"
        :key="index"
        :roleItem="{
          ...roleItem,
          col: index == 0 || index == 1 ? 6 : 4,
          image: 'https://i.blogs.es/4cb96c/good-doctor-season-3-episode-11/1366_2000.jpeg',
        }"
      />
    </b-row>
  </div>
</template>

<script>
import { BRow } from "bootstrap-vue";
import CardRole from "@/views/admin/roles-permissions/CardRole.vue";

import axiosR from "@/services/admin/roles";

export default {
  components: {
    BRow,
    CardRole,
  },
  data() {
    return {
      roles: [],
    };
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    getRoles() {
      axiosR.rolesList().then(({ data }) => {
        this.roles = data
      });
    },
  },
};
</script>

<style>
</style>